import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss'],
})
export class IntroductionComponent implements OnInit {
  lang$ = this.translocoService.langChanges$;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {}

  onChangeLanguage(code: string) {
    this.translocoService.setActiveLang(code);
  }
}
