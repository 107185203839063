import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

import { ContactFormComponent } from './contact-form/contact-form.component';
import { TranslocoRootModule } from '../transloco-root.module';
import { SharedModule } from '../shared/shared.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const declarations = [ContactFormComponent];

@NgModule({
  declarations: [...declarations],
  imports: [
    FormsModule,
    InputTextModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoRootModule,
  ],
  exports: [...declarations],
})
export class ContactFormModule {}
