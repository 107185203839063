<ng-container *transloco="let t; read: 'introduction'">
  <app-card>
    <div class="language-switcher">
      <button
        [class.active]="(lang$ | async) === 'de'"
        (click)="onChangeLanguage('de')"
      >
        De
      </button>
      <button
        [class.active]="(lang$ | async) === 'en'"
        (click)="onChangeLanguage('en')"
      >
        En
      </button>
    </div>

    <b>{{ t('content.title') }}</b>
    <p>
      {{ t('content.paragraph1') }}
    </p>

    <p>
      {{ t('content.paragraph2') }}
    </p>

    <p>
      {{ t('content.paragraph3') }}
    </p>

    <strong
      >{{ t('content.bottomLine') }}
      <img src="/assets/images/bulb.svg" alt="{{ t('content.bottomLine') }}"
    /></strong>

    <div class="buttons">
      <a class="button-wrapper" routerLink="/calculator">
        <app-button theme="secondary">{{ t('content.button') }}</app-button>
      </a>
    </div>
  </app-card>
</ng-container>
