import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  NumberFormatOptions,
  TranslocoDecimalPipe,
} from '@ngneat/transloco-locale';
import { TranslocoService } from '@ngneat/transloco';

import { Language } from 'src/app/interfaces';

@Pipe({
  name: 'age',
  pure: false,
})
export class AgePipe implements PipeTransform {
  constructor(
    protected sanitizer: DomSanitizer,
    private translocoService: TranslocoService,
    private translocoDecimalPipe: TranslocoDecimalPipe
  ) {}

  public transform(
    value: number,
    lang: Language | string,
    sentence: boolean = false,
    isDecimal: boolean = false,
    numberFormatOptions?: NumberFormatOptions
  ): string {
    switch (lang) {
      case Language.English:
        return this.common(value, isDecimal, numberFormatOptions);
      case Language.German:
        return this.german(value, sentence, isDecimal, numberFormatOptions);
      case Language.Polish:
        return this.polish(value);
    }
  }

  private common(
    value: number,
    isDecimal: boolean = false,
    numberFormatOptions?: NumberFormatOptions
  ): string {
    const translation = this.translocoService.translate(
      value === 1 ? 'years.single' : 'years.plural'
    );

    let valueString = `${value}`;
    if (isDecimal) {
      valueString = this.translocoDecimalPipe.transform(
        value,
        numberFormatOptions
      );
    }

    return `${valueString} ${translation}`;
  }

  private german(
    value: number,
    sentence: boolean,
    isDecimal: boolean = false,
    numberFormatOptions?: NumberFormatOptions
  ): string {
    const translation = this.translocoService.translate(
      value === 1
        ? sentence
          ? 'years.singleSentence'
          : 'years.single'
        : sentence
        ? 'years.pluralSentence'
        : 'years.plural'
    );

    if (value === 1 && sentence) {
      return translation;
    }

    let valueString = `${value}`;
    if (isDecimal) {
      valueString = this.translocoDecimalPipe.transform(
        value,
        numberFormatOptions
      );
    }

    return `${valueString} ${translation}`;
  }

  private polish(value: number): string {
    return `${value}`;
  }
}
