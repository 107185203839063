import { NgModule } from '@angular/core';

import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#ffffff',
      text: '#1d1e40',
      link: '#1d1e40',
    },
    button: {
      background: '#cc0033',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  type: 'opt-out',
};

@NgModule({
  imports: [NgcCookieConsentModule.forRoot(cookieConfig)],
  exports: [],
  providers: [],
})
export class CookiesRootModule {}
